import { createApp as VueCreateApp, reactive } from 'vue';

try {
    if (needsPollyfills()) {
        await import('o365.modules.pollyfills.ts');
    }
} catch (ex) {
    const { logger } = await import('o365-utils')
    logger.error('Failed to apply pollyfills\n', ex)
}

export const globalState = reactive({
    isMobile: false,
    isTablet: false,
});

export async function createApp(options: any, o365Options: {
    includeProperties?: boolean;
    includeComponents?: boolean;
    includeDirectives?: boolean;
    customIsCustomElement?: any;
    customResolveComponents?: any;
    /**
     * Check if the current device is mobile. If so install
     * the mobile plugin before creating the app
     */
    checkForMobile?: boolean;
    /** @deprecated Not in use */
    appId?: string;
} = {
        includeProperties: true,
        includeComponents: true,
        includeDirectives: true,
        customIsCustomElement: undefined,
        customResolveComponents: undefined,
        appId: 'site'
    }): Promise<any> {
    const includeProperties = o365Options.includeProperties ?? true;
    // const includeComponets = o365Options.includeComponents ?? true;
    const includeDirectives = o365Options.includeDirectives ?? true;
    // const customIsCustomElement = o365Options.customIsCustomElement;
    const customResolveComponents = o365Options.customResolveComponents;

    const { initializeConfigs} = await import('o365-modules');
    await initializeConfigs();

    const app = VueCreateApp(options);
    if (o365Options.checkForMobile !== false) {
        try {
            // const { MediaQueryManager } = await import('o365.GlobalState.ts');
            const { isMobile, isTablet } = await import('o365.GlobalState.ts');
            // const isMobile = MediaQueryManager.getQueryMatchState("isMobile");
            // const isTablet = MediaQueryManager.getQueryMatchState("isTablet");
            globalState.isMobile = isMobile.value;
            globalState.isTablet = isTablet.value;
            if (isMobile.value || isTablet.value) {
                const { MobilePlugin } = await import('o365-mobile');
                app.use(MobilePlugin);
            } else {
                document.body.classList.add('disable-overscroll');
            }
        } catch (ex) {
            const { logger } = await import('o365-utils')
            logger.error('Could not load mobile plugin\n', ex)
        }
    }


    // app.config.compilerOptions.isCustomElement = customIsCustomElement ?? ((tag) => {
    //     return tag.startsWith('o365-') || tag.startsWith('partial');
    // });

    if ((includeProperties ?? true) || includeDirectives) {
        const { default: GlobalProperties} = await import('./GlobalProperties.ts');
        app.use(GlobalProperties, {
            includeProperties: includeProperties,
            includeDirectives: includeDirectives,
        })
    }

    app.provide('resolveComponent', customResolveComponents ?? ((name) => {
        let component = app._component.components[name];

        if (component) {
            return component;
        }

        if (name.includes('-')) {
            const pascalName = name.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');

            component = app._component.components[pascalName];
        } else {
            const kebabName = name.replace(/([A-Z][a-z])/g, '-$1').toLowerCase();

            component = app._component.components[kebabName];
        }

        return component;
    }));

    if (window.top === window.self) {
        const checkNotes = () => {
            import('o365-modules').then((modules) => {
                const shouldOpenNotes = modules.localStorageHelper.getItem('o365-notes-app-open', { global: true }) === 'true';
                if (shouldOpenNotes) {
                    import('o365.libraries.modules.NotesApp.ts').then((notesModule) => {
                        window.setTimeout(() => {
                            notesModule.default();
                        }, 100);
                    }); 
                }
            });
        }
        if (app._component.mounted) {
            const mountedFunc = app._component.mounted;
            app._component.mounted = function () {
                mountedFunc.call(this);
                checkNotes();
            };
        } else {
            app._component.mounted = checkNotes;
        }
    } else {
        // App created inside an iframe. Notify parent window. 
        window.parent.postMessage({ type: 'o365-system', action: 'app-created' });
        const parentMessageChannel: { connected: boolean, port: MessagePort} = { connected: false, port: null };
        app.provide('o365-parent-message-channel', parentMessageChannel)
        addEventListener('message', (pEvent) => {
            const data = pEvent.data;
            if (data == null || typeof data !== 'object' || data.type !== 'o365-system' || data.action !== 'establish-channel') {
                return;
            }
            if (window.parent != pEvent.source || pEvent.ports[0] == null) { return; }
            parentMessageChannel.port = pEvent.ports[0];
            parentMessageChannel.connected = true;
        });
    }

    return app;
}

function needsPollyfills() {
    return window.BroadcastChannel == null || window.crypto.randomUUID == null; 
}
